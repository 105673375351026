import React from "react"
import { Link } from "gatsby"

export default function WhyXccelerata() {
    return (
        <>
            <section id="about" className="clearfix">
                <div id="values_banner">
                    <div className="container">

                        <div
                            className="values_banner-info"
                            data-aos="zoom-in"
                            data-aos-delay="100"
                        >
                            <div className="section-header">
                                <h2>Our Vision</h2>
                            </div>
                            <p className="banner-text">
                                To nudge our founders, entrepreneurs, partners, investors, and the society towards a constructive and sustainable growth.
                            </p>
                            <div className="section-header">
                                <h2>Our Approach</h2>
                            </div>
                            <p className="banner-text" style={{ position: "relative", marginBottom: "20px" }}>
                                We provide a holistic VC ecosystem of technical expertise, 24x7 support, global network and years of industry experience to ambitious minds looking to innovate, accelerate and change the world.
                            </p>
                        </div>
                    </div>


                </div>

            </section>
           
            <section id="about">
                <div className="container" data-aos="fade-up">
                    
                    <h2 style={{ textAlign: 'center', marginTop: "40px", }} className="section-header"><b> Why Entrepreneurs Love Us </b></h2>
                    <section id="our-products">
                        <div className="row row-eq-height justify-content-center">
                            <div className="col-lg-8 mb-12 col-sm-4">
                                <p className="bg-values img-fluid"></p>
                            </div>

                        </div>

                        <div className="container" data-aos="fade-up">

                            <div className="row row-eq-height">
                                <div className="col-lg-4 mb-4">
                                    <div className="card" data-aos="zoom-in" data-aos-delay="100">
                                        {/* <i className="bi bi-lightbulb icon-center" style={{ color: "red" }}></i> */}
                                        <div className="card-body">
                                            <h5 className="card-title" > <b>Smart|Fast Technology Innovation</b></h5>
                                            <p className="card-text">
                                                Systemize, accelerate, or incubate your business efficiently with our creative and disruptive technology.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-4" >
                                    <div className="card" data-aos="zoom-in" data-aos-delay="200" >
                                        {/* <i className="bi bi-graph-up icon-center" style={{ color: "red" }}></i> */}
                                        <div className="card-body">
                                            <h5 className="card-title"><b>Business Expansion, Integration & Optimization</b></h5>
                                            <p className="card-text">
                                                Implement your desired vision with Our Strategic Thinking, Domain knowledge, and partner ecosystem.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-4" >
                                    <div className="card" data-aos="zoom-in" data-aos-delay="300" >
                                        {/* <i class="bi bi-building icon-center" style={{ color: "red" }}></i> */}
                                        <div className="card-body">
                                            <h5 className="card-title"><b>Business Incubation</b></h5>
                                            <p className="card-text">
                                                Establish or scale your business within & beyond North America with our skilful marketing, scalable systems, smart money, and expert talent
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-4" >
                                    <div className="card" data-aos="zoom-in" data-aos-delay="200" >
                                        {/* <i className="bi bi-graph-up icon-center" style={{ color: "red" }}></i> */}
                                        <div className="card-body">
                                            <h5 className="card-title"><b>Rapid Change Opportunity</b> </h5>
                                            <p className="card-text">
                                                Execute a new idea timely in the fast-paced market and reap maximum benefits with our custom technology development, specialized partners and business incubation services.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-4" >
                                    <div className="card" data-aos="zoom-in" data-aos-delay="200" >
                                        {/* <i className="bi bi-graph-up icon-center" style={{ color: "red" }}></i> */}
                                        <div className="card-body">
                                            <h5 className="card-title"><b>Experts At Your Exposure</b></h5>
                                            <p className="card-text">
                                                Our deep rooted network of local and international experts will advise, mentor, and guide you in leveraging and scaling unique business ideas and products.
                                            </p>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-lg-4 mb-4" >
                                    <div className="card" data-aos="zoom-in" data-aos-delay="200" >
                                        {/* <i className="bi bi-graph-up icon-center" style={{ color: "red" }}></i> */}
                                        <div className="card-body">
                                            <h5 className="card-title"><b>Partner Ecosystem</b></h5>
                                            <p className="card-text">
                                                An extensive ecosystem of trusted partners providing comprehensive array of products and services to help you develop, scale, and expand globally.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                       
                        <br></br>
                        <div className="row row-eq-height justify-content-center">

                            <div className="col-lg-4 mb-4">

                                <center>

                                    <Link to="/contact" target="_blank" rel="noopener noreferrer" className="page-button" style={{ width: "300px", }}>
                                        <b>Let's Meet & Discuss Growth </b>
                                    </Link>
                                </center>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </>
    )
}
